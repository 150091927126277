<template>
  <div>
    <BaseBread style="margin-left: 5vh">
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>联系我们</BaseBreadItem>
    </BaseBread>
    <div id="company-contact">
      <h2>联系我们</h2>
      <div>
        <div id="company-contact-container">
          <div id="container-left">
            <iframe src="/map.html" frameborder="0" style="width: 100%;height: 100%"></iframe>
          </div>
          <div id="container-right">
            <div class="right-box">
              <div class="right-icon-box">
                <font-awesome-icon icon="fa-solid fa-location-dot"/>
              </div>
              <div class="right-content">
                <h4 class="animated fadeInLeft">公司地址：</h4>
                <p class="animated fadeInRight">浙江省杭州市西湖区三墩镇西园路10号A208室</p>
              </div>
            </div>
            <div class="right-box">
              <div class="right-icon-box">
                <font-awesome-icon icon="fa-solid fa-phone-volume"/>
              </div>
              <div class="right-content">
                <h4 class="animated fadeInLeft">联系人：石先生</h4>
                <p class="animated fadeInRight">13816118728，13385718256</p>
                <p class="animated fadeInRight">热线：0571-86705198</p>
              </div>
            </div>
            <div class="right-box">
              <div class="right-icon-box">
                <font-awesome-icon icon="fa-solid fa-envelope"/>
              </div>
              <div class="right-content">
                <h4 class="animated fadeInLeft">联系邮箱：</h4>
                <p class="animated fadeInRight">jhangtech@163.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import BaseBread from "@/components/library/base-bread.vue";
export default {
  components: {BaseBread, BaseBreadItem},
  setup() {

    return {}
  }
}
</script>

<style lang='less' scoped>
#company-contact {
  height: 800px;
  margin-left: 25px;
  margin-right: 25px;
  background-color: #fff;
  background-image: url("../../assets/images/about/contact/contact-bg.png");

  :first-child div {
    float: left;
    background-color: #fff;
    position: relative;
  }

  #company-contact-container {
    margin-top: 5vh;
    height: 450px;
    box-shadow: 0 0 10px #ccc;
    margin-left: 5%;
    margin-right: 5%;

    #container-right {
      width: 50%;
      height: 100%;
      background-color: #10102e;


      .right-box {
        background-color: transparent;
        width: 100%;
        height: 150px;

        .right-icon-box {
          margin-left: 5vh;
          margin-top: 5vh;
          margin-right: 5vh;
          border-radius: 5px;
          width: 60px;
          height: 60px;
          text-align: center;
          float: left;
          line-height: 2;
          background-color: #fd3c00;
          color: #ffffff;
          font-size: 30px;
        }

        .right-content {
          background-color: transparent;
          margin-top: 6vh;
          color: #bfbbc2;
        }

        :after {
          content: '';
          display: block;
          clear: both;
        }
      }
    }

    #container-left {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }


  h2 {
    font-size: 40px;
    margin-left: 10vh;
    padding-top: 3vh;
  }
}
</style>
